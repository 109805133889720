;
$a-color1: #70C1FF;
$a-color2: #3F92D2;
$a-color3: #0B61A4;
$a-color4: #033E6B;
$b-color1: #FF9200;
$b-color2: #F15C22; //#FF8400, FF7300;
$c-color1: #73ff15;

//$root-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif !default;
//$root-font-size: 1rem !default;
//$root-line-height: 1.5 !default;

$body-color: $a-color3;
$body-muted: $a-color4;
$body-bg: $a-color1;
$link-color: $b-color2;
$heading-color: $b-color2;
//
//$border-color: $gray-2 !default;
//$border-radius: 300px !default;
//$padding-v: 1em !default;
//$padding-h: 1.5em !default;
//$heading-spacing: 0.5rem !default;
//$section-spacing: 2rem !default;
//$sidebar-width: 18rem !default;
//
//$large-breakpoint: 49rem !default;
//$large-font-size: 1.25rem !default;
//
//$box-shadow-size: 1px !default;
//$box-shadow-opacity: 0.16 !default;
//$default-box-shadow: $box-shadow-size
//                     $box-shadow-size
//                     $box-shadow-size
//                     rgba(0, 0, 0, $box-shadow-opacity);
//
//$code-font-family: Menlo, Monaco, "Courier New", monospace !default;
//$code-color: #bf616a !default;

//// Hyde theming
$sidebar-bg-color: $a-color3;
$sidebar-fg-color: $a-color1;
$sidebar-sticky: true;
$layout-reverse: false;
//
$sidebar-title-color: $b-color1;
//$sidebar-link-color: $sidebar-fg-color !default;
//$sidebar-text-color: rgba($sidebar-fg-color, 0.75) !default;
//$sidebar-icon-color: rgba($sidebar-fg-color, 0.85) !default;

@import "hydeout";